

import { firestore } from '@/firebase';
import { USER_GETTERS } from '@/store/user/getters';
import { Phase } from '@/types';
import { format } from 'date-fns';
import { fr, nlBE } from 'date-fns/locale';
import { collection, collectionGroup, getDoc, getDocs, orderBy, query, where, doc, Timestamp, addDoc } from 'firebase/firestore';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class ProgramWebinarsCard extends Vue {
    @Prop({ required: true }) userId!: string;
    @Prop({ required: true }) phases!: Phase[];

    open = false;
    webinars = [] as any[];

    registerForWebinarDialog = false;
    selectedWebinar = '';
    selectedPhase = '';

    get user() {
        return this.$store.getters[USER_GETTERS.ACTING_USER];
    }

    @Watch('registerForWebinarDialog')
    async registerForWebinarDialogChanged() {
        if (this.registerForWebinarDialog && this.webinars.length === 0) {
            await this.fetchWebinars();
        }
    }

    async fetchWebinars() {
        let fetchedEvents = await getDocs(query(collection(firestore, 'webinars'), where('start', '>=', new Date()), orderBy('start')));
        let events = fetchedEvents.docs.map(e => {
            return {
                ...e.data(),
                id: e.id,
                title: `${e.data().title} - ${format((e.data().start as Timestamp).toDate(), 'dd MMMM yyyy hh:mm', {
                    locale: this.user.language === 'nl-be' ? nlBE : fr
                })}`,
                start: (e.data().start as Timestamp).toDate(),
                end: (e.data().end as Timestamp).toDate(),
            }
        });
        this.webinars = events;
    }

    async mounted() {
        await this.fetchWebinars();
    }

    @Watch('phases', { immediate: true })
    async onPhasesChange() {
        for (const phase of this.phases) {
            const start = phase.start.toDate();
            const end = phase.end.toDate();
            const now = new Date();
            if (now >= start && now <= end) {
                this.selectedPhase = phase.id!;
                break;
            }
        }
    }

    async registerForWebinar() {
        if (!this.selectedWebinar) return;

        let coach = '';

        if (this.user.type === 'ADMIN') {
            const programsOfUser = await getDocs(query(collection(firestore, 'programs'), where('participant', '==', this.userId)))
            if (!programsOfUser.empty) {
                coach = programsOfUser.docs[0].data().coach as string;
            } else {
                coach = this.user.id;
            }
        } else {
            coach = this.user.id;
        }

        await addDoc(collection(firestore, 'event-attendees'), {
            webinarId: this.selectedWebinar,
            user: this.userId,
            coach,
            present: false,
            addedActivity: false,
        })

        await addDoc(collection(firestore, 'webinar-invite-test'), {
            user: this.userId,
            webinar: this.selectedWebinar,
        })


        this.registerForWebinarDialog = false;
        await this.fetchWebinars();
    }
}
